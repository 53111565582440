@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Regular.eot');
  src: url('../fonts/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
      url('../fonts/SFProDisplay-Regular.woff') format('woff'),
      url('../fonts/SFProDisplay-Regular.ttf') format('truetype'),
      url('../fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Bold.eot');
  src: url('../fonts/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/SFProDisplay-Bold.woff2') format('woff2'),
      url('../fonts/SFProDisplay-Bold.woff') format('woff'),
      url('../fonts/SFProDisplay-Bold.ttf') format('truetype'),
      url('../fonts/SFProDisplay-Bold.svg#SFProDisplay-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../fonts/SFProText-Bold.eot');
  src: url('../fonts/SFProText-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/SFProText-Bold.woff2') format('woff2'),
      url('../fonts/SFProText-Bold.woff') format('woff'),
      url('../fonts/SFProText-Bold.ttf') format('truetype'),
      url('../fonts/SFProText-Bold.svg#SFProText-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../fonts/SFProText-Regular.eot');
  src: url('../fonts/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/SFProText-Regular.woff2') format('woff2'),
      url('../fonts/SFProText-Regular.woff') format('woff'),
      url('../fonts/SFProText-Regular.ttf') format('truetype'),
      url('../fonts/SFProText-Regular.svg#SFProText-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../fonts/SFProText-Semibold.eot');
  src: url('../fonts/SFProText-Semibold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/SFProText-Semibold.woff2') format('woff2'),
      url('../fonts/SFProText-Semibold.woff') format('woff'),
      url('../fonts/SFProText-Semibold.ttf') format('truetype'),
      url('../fonts/SFProText-Semibold.svg#SFProText-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../fonts/SFProText-Light.eot');
  src: url('../fonts/SFProText-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/SFProText-Light.woff2') format('woff2'),
      url('../fonts/SFProText-Light.woff') format('woff'),
      url('../fonts/SFProText-Light.ttf') format('truetype'),
      url('../fonts/SFProText-Light.svg#SFProText-Light') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@layer base {
  html {
    font-family: "SF Pro Display", system-ui, sans-serif;
  }

  body {
    font-family: "SF Pro Display", system-ui, sans-serif;
  }
}

@layer utilities {
  .my-rotate-y-180 {
    transform: rotateY(180deg)
  }
  .preserve-3d {
    transform-style: preserve-3d
  }
  .perspective {
    perspective: 1000px
  }
  .backface-hidden {
    backface-visibility: hidden
  }
}

@layer components {
  .btn {
    @apply inline-flex items-center justify-center px-5 py-3 mb-3 text-base font-bold text-center rounded-lg capitalize;
  }
  .btn-primary {
    @apply text-white bg-malacite hover:bg-green-500;
  }
  .btn-light {
    @apply text-dark2 bg-grey2 hover:bg-gray-300;
  }
  .btn-secondary {
    @apply text-dark2 border-2 border-dark2 hover:bg-gray-100 dark:text-white dark:border-white dark:hover:bg-gray-500;
  }
  .btn-danger {
    @apply text-white bg-red-600 hover:bg-red-700;
  }
  .btn-block {
    @apply w-full;
  }
}

header ul > li a.active {
  font-weight: 700;
}
.Toastify__toast-body {
  font-size: 14px !important;
}

.Toastify__toast-body .Toastify__toast-icon {
  width: 16px !important;
  height: 16px !important;
}